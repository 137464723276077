import React, { useState, Fragment } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import Fade from "react-reveal/Fade";
import { minus } from "react-icons-kit/entypo/minus";
import { plus } from "react-icons-kit/entypo/plus";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Box from "@common/src/components/Box";
import Text from "@common/src/components/Text";
import Heading from "@common/src/components/Heading";
import Button from "@common/src/components/Button";
import Container from "@common/src/components/UI/Container";
import GlideCarousel from "@common/src/components/GlideCarousel";
import GlideSlide from "@common/src/components/GlideCarousel/glideSlide";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "@common/src/components/Accordion";
import Image from "@common/src/components/Image";

import PricingTable, {
  PricingHead,
  PricingButton,
  PricingList,
  ListItem,
  BenefitSection,
  BenefitHead,
  ProductSection,
} from "./productCard.style";
import { InteriorWrapper } from "../../styling-wrappers/interior.style";

const PricingSection = ({
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  descriptionStyle,
  buttonFillStyle,
  listContentStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      produkKami: allContentfulProdukKami(
        sort: { fields: orderBy, order: ASC }
      ) {
        nodes {
          title
          productLink
          image {
            gatsbyImageData
          }
          description {
            internal {
              content
            }
          }
          listProduk: childContentfulProdukKamiProdukListJsonNode {
            details {
              id
              title
            }
          }
        }
      }
      Tick: file(relativePath: { eq: "image/produkKami/tick.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [state] = useState({
    data: Data.produkKami.nodes,
    active: true,
  });

  const data = state.data;
  const tick = Data.Tick.childImageSharp.fluid;

  const pricingCarouselOptions = {
    type: "slide",
    perView: 3,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        peek: {
          before: 100,
          after: 100,
        },
      },
      990: {
        perView: 1,
        peek: {
          before: 160,
          after: 160,
        },
      },
      767: {
        perView: 1,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20,
        },
      },
    },
  };

  // console.log(data);
  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Fade up delay={100}>
            <Heading {...secHeading} content="Produk Kami" />
          </Fade>
        </Box>
        <InteriorWrapper>
          <ProductSection>
            <Fade up delay={100}>
              <Box {...row}>
                <GlideCarousel
                  carouselSelector="pricing-carousel"
                  options={pricingCarouselOptions}
                  nextButton={<span className="next_arrow" />}
                  prevButton={<span className="prev_arrow" />}
                >
                  <>
                    {data.map((productDetail, index) => (
                      <GlideSlide key={`pricing-table-${index}`}>
                        <PricingTable className="pricing_table">
                          <PricingHead>
                            <GatsbyImage
                              image={getImage(productDetail.image)}
                            />
                            {/* <GatsbyImage
                              image={getImage(productDetail.produkImg)}
                            /> */}
                            <Text
                              content={
                                productDetail.description.internal.content
                              }
                              {...descriptionStyle}
                            />
                          </PricingHead>
                          <Accordion>
                            <Fragment>
                              <AccordionItem
                                key={`pricing-table-list-${index}`}
                              >
                                <BenefitHead>
                                  <Fragment>
                                    <AccordionTitle>
                                      <Fragment>
                                        <Heading
                                          as="h4"
                                          content="Apa saja yang Anda dapatkan?"
                                        />
                                        <IconWrapper>
                                          <OpenIcon>
                                            <Icon icon={minus} size={20} />
                                          </OpenIcon>
                                          <CloseIcon>
                                            <Icon icon={plus} size={20} />
                                          </CloseIcon>
                                        </IconWrapper>
                                      </Fragment>
                                    </AccordionTitle>
                                  </Fragment>
                                </BenefitHead>
                                <Fragment>
                                  <BenefitSection>
                                    <AccordionBody>
                                      <PricingList>
                                        {productDetail.listProduk.details.map(
                                          (item, index) =>
                                            productDetail.title ===
                                            "SkinGEN-ME" ? (
                                              item.id !== 7 ? (
                                                <ListItem
                                                  key={`pricing-table-list-${index}`}
                                                  className="list-item"
                                                >
                                                  <Image
                                                    fluid={
                                                      (tick !== null) |
                                                      undefined
                                                        ? tick
                                                        : {}
                                                    }
                                                    src={tick.src}
                                                    className="tick-icon"
                                                    objectFit="contain"
                                                    alt="tick"
                                                  />
                                                  <Text
                                                    content={item.title}
                                                    {...listContentStyle}
                                                  />
                                                </ListItem>
                                              ) : null
                                            ) : productDetail.title ===
                                              "FitGEN-ME" ? (
                                              item.id !== 1 && item.id !== 6 ? (
                                                <ListItem
                                                  key={`pricing-table-list-${index}`}
                                                  className="list-item"
                                                >
                                                  <Image
                                                    fluid={
                                                      (tick !== null) |
                                                      undefined
                                                        ? tick
                                                        : {}
                                                    }
                                                    src={tick.src}
                                                    className="tick-icon"
                                                    objectFit="contain"
                                                    alt="tick"
                                                  />
                                                  <Text
                                                    content={item.title}
                                                    {...listContentStyle}
                                                  />
                                                </ListItem>
                                              ) : (
                                                <ListItem
                                                  key={`pricing-table-list-${index}`}
                                                  className="list-item"
                                                >
                                                  <Text
                                                    fontWeight="600"
                                                    content={item.title}
                                                    {...listContentStyle}
                                                  />
                                                </ListItem>
                                              )
                                            ) : (
                                              <ListItem
                                                key={`pricing-table-list-${index}`}
                                                className="list-item"
                                              >
                                                <Image
                                                  fluid={
                                                    (tick !== null) | undefined
                                                      ? tick
                                                      : {}
                                                  }
                                                  src={tick.src}
                                                  className="tick-icon"
                                                  objectFit="contain"
                                                  alt="tick"
                                                />
                                                <Text
                                                  content={item.title}
                                                  {...listContentStyle}
                                                />
                                              </ListItem>
                                            )
                                        )}
                                      </PricingList>
                                    </AccordionBody>
                                  </BenefitSection>
                                </Fragment>
                              </AccordionItem>
                            </Fragment>
                          </Accordion>
                          <PricingButton>
                            <Link to={productDetail.productLink}>
                              <Button
                                title={`Mulai Sekarang`}
                                className={`btn-${productDetail.title.replace(
                                  / /g,
                                  ""
                                )}`}
                                {...buttonFillStyle}
                              />
                            </Link>
                          </PricingButton>
                        </PricingTable>
                      </GlideSlide>
                    ))}
                  </>
                </GlideCarousel>
              </Box>
            </Fade>
          </ProductSection>
        </InteriorWrapper>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["100px", "100px", "100px", "140px", "160px"],
    pb: ["60px", "80px", "80px", "100px"],
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center",
  },
  secTitleWrapper: {
    mb: ["50px", "20px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#2aa275",
    mb: "5px",
  },
  secHeading: {
    textAlign: "center",
    fontSize: ["22px", "24px", "27px", "32px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: ["42px", "40px", "50px", "60px"],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: "15px",
    pl: "15px",
  },
  nameStyle: {
    fontSize: ["20px", "20px", "22px", "22px", "22px"],
    fontWeight: "500",
    color: "headingColor",
    letterSpacing: "-0.025em",
    textAlign: "center",
    mb: "12px",
  },
  descriptionStyle: {
    fontSize: ["15px", "16px", "16px", "16px", "16px"],
    color: "textColor",
    lineHeight: "1.75",
    textAlign: "center",
    mb: "0",
  },
  priceStyle: {
    as: "span",
    display: "block",
    fontSize: ["36px", "36px", "40px", "40px", "40px"],
    color: "headingColor",
    textAlign: "center",
    mb: "5px",
    letterSpacing: "-0.025em",
  },
  priceLabelStyle: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    color: "textColor",
    lineHeight: "1.75",
    textAlign: "center",
    mb: "0",
  },
  buttonStyle: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    pl: "10px",
    pr: "10px",
    bg: "#fff",
    color: "#2aa275",
    colors: "primaryWithBg",
    width: "222px",
    maxWidth: "100%",
  },
  buttonFillStyle: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    color: "white",
    borderRadius: "4px",
    pl: "10px",
    pr: "10px",
    colors: "primaryWithBg",
    width: "200px",
    maxWidth: "100%",
  },
  listContentStyle: {
    fontSize: ["15px", "16px", "16px", "16px", "16px"],
    color: "textColor",
    mb: "0",
  },
};

export default PricingSection;
