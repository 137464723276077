import React from 'react';
import ShapeWrapper from './tiltShape.style';

const TiltShape = ({ className, color }) => {
  // Add all classs to an array
  const addAllClasses = ['tilt_shape'];
  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <ShapeWrapper className={addAllClasses.join(' ')}>
      <svg width="2356" height="900" viewBox="0 0 2356 500">
        <defs>
          <clipPath>
            <path width="2356" height="900" />
          </clipPath>
        </defs>
        <g data-name="Shape">
          <path
            data-name="Rectangle 134"
            width="2356"
            height="900"
            d="M0 300 C600,50 1200,200 2356,-100 L2356 0 L2356 2356 L 0 900 Z"
            fill={color || '#fff'}
          />
        </g>
      </svg>
    </ShapeWrapper>
  );
};

export default TiltShape;
