import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { mediaRecordOutline } from "react-icons-kit/typicons/mediaRecordOutline";
import { plus } from "react-icons-kit/typicons/plus";
import { starOutline } from "react-icons-kit/typicons/starOutline";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Text from "@common/src/components/Text";
import Heading from "@common/src/components/Heading";
import Container from "@common/src/components/UI/Container";
import FeatureBlock from "@common/src/components/FeatureBlock";

import { SectionHeader } from "../../styling-wrappers/appModern.style";
import SectionWrapper, { FeatureWrapper } from "./newLifeSteps.style";

const NewLifeSteps = () => {
  const data = useStaticQuery(graphql`
    query {
      steps: allContentfulNewLifeSteps(
        sort: { fields: contentful_id, order: DESC }
      ) {
        nodes {
          id: contentfulid
          title
          desc
          icon {
            gatsbyImageData(width: 200, quality: 100)
          }
          color
        }
      }
    }
  `);

  const { nodes: items } = data.steps;

  return (
    <SectionWrapper id="features">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading content={"4 Langkah untuk Memulai Hidup Baru"} />
          </Fade>
        </SectionHeader>
        <FeatureWrapper>
          {items.map((item) => (
            <Fade up delay={100 * item.id} key={`feature-key${item.id}`}>
              <FeatureBlock
                style={{ "--color": `${item.color}` }}
                icon={
                  <Fragment>
                    <Icon className="plus" icon={plus} />
                    <Icon className="circle" icon={mediaRecordOutline} />
                    <GatsbyImage image={getImage(item.icon)} />
                    <Icon className="star" icon={starOutline} />
                  </Fragment>
                }
                iconPosition="left"
                title={<Heading as="h3" content={item.title} />}
                description={<Text content={item.desc} />}
              />
            </Fade>
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default NewLifeSteps;
