import styled from 'styled-components';
import BannerBG from '@common/src/assets/image/index/banner-home-2-new.webp';

export const BannerWrapper = styled.section`
  padding: 350px 0 50px 0;
  background-image: url(${BannerBG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  @media only screen and (min-width: 991px) {
    background-position: center 35%;
  }
  @media (max-width: 575px) {
    background-size: cover;
    padding: 250px 0 0 0;
  }

  img {
    max-width: 100%;
    /* max-height: 646px; */
    height: auto;
    display: block;
  }

  h2 {
    margin-bottom: 20px;
  }
`;

export const DiscountWrapper = styled.div`
  text-align: center;
`;

export const DiscountLabel = styled.div`
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  border-radius: 4em;
  padding: 9px 22px;
  margin-bottom: 22px;
  background-color: rgba(255, 255, 255, 0.15);
  @media (max-width: 575px) {
    padding: 7px 10px;
  }
`;
