import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "@common/src/theme/agencyModern";
import { ResetCSS } from "@common/src/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "../components/styling-wrappers/agencyModern.style";
import { DrawerProvider } from "@common/src/contexts/DrawerContext";
import Navbar from "../components/public-components/Navbar";
import Banner from "../components/index-components/Banner";
import Product from "../components/index-components/Product";
import Services from "../components/index-components/Service";
import NewLifeSteps from "../components/index-components/NewLifeSteps";
import Subscribe from "../components/index-components/Subscribe";
import Footer from "../components/public-components/Footer";
import Seo from "../components/seo";
import Testimonials from "../components/index-components/Testimonials";
import PopupImage from "../components/PopupImage";
import ButtonContact from "../components/index-components/ButtonContact";
import Layout from "../components/layout";

const indexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Seo
        title="Pemeriksaan Genetik"
        description="GENME membantu Anda memberikan solusi yang personal dan lebih efektif berdasarkan DNA Anda, apa pun tujuan Anda, GENME dapat mempersonalisasikan jalan Anda menuju kehidupan yang lebih sehat dan bahagia."
      />
      <ResetCSS />
      <GlobalStyle />
      <Layout>
        <PopupImage/>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar origin="Beranda" />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <Product />
          <Services />
          <NewLifeSteps />
          <Testimonials />
          <Subscribe />
          <Footer />
          <ButtonContact/>
        </ContentWrapper>
      </Layout>
    </ThemeProvider>
  );
};

export default indexPage;
