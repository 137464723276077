import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";

import Box from "@common/src/components/Box";
import Text from "@common/src/components/Text";
import Heading from "@common/src/components/Heading";

import Container from "@common/src/components/UI/Container";
import TiltShape from "../../public-components/TiltShape";

import { BannerWrapper } from "./service.style";

const BannerSection = ({
  row,
  contentWrapper,
  title,
  description,
  imageWrapper,
}) => {
  // const Data = useStaticQuery(graphql`
  //   query {
  //     bannerImage: file(
  //       relativePath: { eq: "image/produkKami/GENME-KITS-NEW.png" }
  //     ) {
  //       childImageSharp {
  //         fluid(maxWidth: 1170, quality: 100) {
  //           ...GatsbyImageSharpFluid_noBase64
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <BannerWrapper id="banner_section">
      <TiltShape />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Fade up delay={100}>
              <Heading
                {...title}
                content="Bagaimana tes DNA dapat membantu Anda dalam mencapai tujuan kesehatan Anda?"
              />
            </Fade>
            <Fade up delay={100}>
              <Text
                {...description}
                content="GENME membantu Anda menjadi versi terbaik dari diri Anda. Kami akan memberikan solusi yang personal dan lebih efektif yg sepenuhnya unik berdasar profil DNA Anda, yang sepenuhnya unik untuk profil DNA Anda. Apa pun tujuan Anda, GENME dapat mempersonalisasi jalan Anda menuju kehidupan yang lebih sehat dan bahagia."
              />
            </Fade>
          </Box>
          {/* <Box {...imageWrapper}>
            <Fade bottom>
              <Image
                fluid={Data.bannerImage.childImageSharp.fluid}
                alt="banner image"
              />
            </Fade>
          </Box> */}
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  contentWrapper: {
    width: ["100%", "100%", "80%", "55%", "90%"],
    mb: "40px",
  },
  imageWrapper: {
    width: "100%",
  },
  title: {
    textAlign: "center",
    fontSize: ["22px", "24px", "27px", "32px", "40px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: ["42px", "40px", "50px", "60px"],
  },
  description: {
    fontSize: ["15px", "16px", "16px", "16px", "16px"],
    color: "#0f2137",
    lineHeight: "1.75",
    mb: "0",
    textAlign: "center",
  },
  discountAmount: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "700",
    color: "#00865b",
    mb: 0,
    as: "span",
    mr: "0.4em",
  },
  discountText: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "700",
    color: "#fff",
    mb: 0,
    as: "span",
  },
  fillButton: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    colors: "secondaryWithBg",
    minWidth: ["auto", "150px"],
    height: ["40px", "46px"],
    minHeight: "auto",
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: "center",
    mt: "35px",
  },
  button: {
    type: "button",
    fontSize: ["13px", "14px"],
    fontWeight: "600",
    borderRadius: "4px",
    p: ["0px 15px", "8px 22px"],
    color: "#fff",
    colors: "secondary",
    height: ["40px", "46px"],
    minHeight: "auto",
  },
};

export default BannerSection;
