import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";

import Container from "@common/src/components/UI/Container";
import Heading from "@common/src/components/Heading";
import Button from "@common/src/components/Button";
import Text from "@common/src/components/Text";

import SectionWrapper, {
  FooterInner,
  Content,
  SubscriptionForm,
} from "./subscribe.style";

const Subscribe = () => {
  return (
    <SectionWrapper>
      <Container>
        <FooterInner>
          <Fade left delay={100}>
            <Content>
              <Heading as="h3" content="Menyukai Layanan Kami? Ikuti Kami" />
              <Text content="Dapatkan informasi terbaru mengenai pemeriksaan genetik" />
            </Content>
          </Fade>
          <Fade right delay={100}>
            <SubscriptionForm>
              <Link to="/kontak#kontak-subscribe">
                <div>
                  <Button title="Kontak Kami" type="submit" />
                </div>
              </Link>
            </SubscriptionForm>
          </Fade>
        </FooterInner>
      </Container>
    </SectionWrapper>
  );
};

export default Subscribe;
