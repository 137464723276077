import styled from "styled-components";
import { themeGet } from "styled-system";

const ProductSection = styled.div`
  #pricing-carousel {
    padding-bottom: 20px;
    .glide__controls {
      position: absolute;
      top: -30px;
      padding-top: 2px;
        > div {
          > span {
            &.next_arrow {
              width: 45px;
              background-color: ${themeGet("colors.genmeText")};
              @media only screen and (max-width: 667px) {
                width: 30px;
              }

              &::before {
                background-color: ${themeGet("colors.genmeText")};
                transform: rotate(42deg);
              }

              &::after {
                transform: rotate(-42deg);
                background-color: ${themeGet("colors.genmeText")};
              }
            }
          }

          &:hover {
            > span {
              background-color: ${themeGet("colors.genmeText")};

              &::before,
              &::after {
                background-color: ${themeGet("colors.genmeText")};
              }

              &.next_arrow {
                width: 45px;
                &::before {
                  transform: rotate(42deg);
                }
                &::after {
                  transform: rotate(-42deg);
                }
              }
            }
          }
        }
    }
  }
`;

const PricingTable = styled.div`
  /* border: 1px solid #f2f4f7; */
  box-shadow: 1px 1px 10px #e1e3e6;
  border-radius: 5px;
  padding: 60px 45px;
  border-radius: 5px;
  margin: 10px;
  margin-top: 40px;
  @media (max-width: 767px) {
    padding: 45px 35px;
  }
`;

const PricingHead = styled.div`
  margin-bottom: 40px;
  margin-top: 5px;
  p {
    height: 150px;
  }
  img {
    padding-bottom: 20px;
  }
`;

const BenefitHead = styled.div`
  background-color: #f4f4f4;
  border-radius: 10px;

  h4 {
    margin: 0;
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
  }
  > div {
    margin-right: 15px;
  }
`;

const PricingPrice = styled.div``;

const BenefitSection = styled.div`
  margin-bottom: 30px;
`;

const PricingButton = styled.div`
  text-align: center;
  margin-bottom: 55px;
  .BUTTON-OLLDDD {
    background: rgb(107, 162, 206);
    background: linear-gradient(
      90deg,
      rgba(107, 162, 206, 1) 9%,
      rgba(117, 191, 194, 1) 31%,
      rgba(227, 218, 129, 1) 63%,
      rgba(253, 191, 90, 1) 92%
    );
  }
  .btn-KiddyGEN-ME {
    background-color: #1aa89d;
  }
  .btn-NutrigenmeLIFE {
    background-color: #116847;
  }
  .btn-SkinGEN-ME {
    background-color: #ed5260;
  }
  .btn-PrimeGEN-ME {
    background-color: #af7a2a;
  }
  .btn-NutriGEN-ME {
    background-color: #e22726;
  }
`;

const PricingList = styled.div`
  margin: 30px 0;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  img {
    max-height: 20px;
    margin-right: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .price_list_icon {
    color: ${themeGet("colors.genmePrimary")};
    margin-right: 10px;
  }
`;

const SwitchWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  .reusecore__switch {
    .reusecore__field-label {
      font-size: 16px;
      font-weight: 400;
      color: #5c636c;
      cursor: pointer;
    }
    input[type="checkbox"] {
      &:checked {
        + div {
          width: 40px !important;
          background-color: ${themeGet("colors.primary")};
          > div {
            left: 17px !important;
          }
        }
      }
      + div {
        background-color: #f0f0f0;
        background-color: #f0f0f0;
        border: 0;
        width: 40px;
        height: 25px;
        > div {
          background-color: #fff;
          box-shadow: 0px 2px 3px 0.24px rgba(31, 64, 104, 0.25);
          width: 21px;
          height: 21px;
          top: 2px;
          left: 2px;
        }
      }
    }
  }
`;

const PricingButtonWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  .reusecore__button {
    font-size: 16px;
    font-weight: 400;
    color: #6f7a87;
    background: #fff;
    height: 50px;
    width: 165px;
    border: 1px solid #e4e9ee;
    &:nth-child(1) {
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 5px;
      border-right-color: transparent;
    }
    &:nth-child(2) {
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 0;
      border-left-color: transparent;
    }
    &.active-item {
      color: #2aa275;
      border-color: #2aa275;
    }
    @media (max-width: 575px) {
      font-size: 14px;
      height: 44px;
      width: 120px;
      padding: 0 5px;
    }
  }
`;

export {
  ProductSection,
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  SwitchWrapper,
  PricingButtonWrapper,
  BenefitSection,
  BenefitHead,
};
export default PricingTable;
