import React from "react";
import Fade from "react-reveal/Fade";
import ReactTyped from "react-typed";
import { Link } from "gatsby";

import Text from "@common/src/components/Text";
import Button from "@common/src/components/Button";
import Heading from "@common/src/components/Heading";
import Container from "@common/src/components/UI/Container";

import BannerWrapper, { BannerContent, Subscribe } from "./banner.style";

const Banner = () => {
  const headerContent = () => {
    return (
      <p>
        Dapatkan&nbsp;
        <span>
          <ReactTyped
            strings={[
              "suplementasi",
              "panduan hidup sehat",
              "panduan olahraga",
              "profil kardiometabolik",
              "info skincare",
              "info perawatan Kulit",
              "info risiko penyakit kulit",
              "program diet",
            ]}
            loop
            typeSpeed={90}
            backSpeed={20}
            backDelay={2000}
            cursorChar="|"
          />
        </span>
        <br />
        yang sesuai dengan GEN mu.
      </p>
    );
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading as="h1" content={headerContent()} />
          </Fade>
          <Fade up delay={200}>
            <Text
              className="banner-caption"
              content="Lakukan Pemeriksaan Genetik Sekarang"
            />
          </Fade>
          <Fade up delay={300}>
            <Subscribe>
              <Link to="/kontak" className="margin-right">
                <Button title="Tes Sekarang" />
              </Link>
              <a
                href="https://customer.genme.id/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button title="Register" className="btn-green" />
              </a>
            </Subscribe>
          </Fade>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
